// import { Notification } from 'element-ui'
import axios from 'axios'
import Router from '@/router'
// import $store from '../store'
// import { getLoginDevice } from '@/globalApi'
//
// const baseURL = process.env.NODE_ENV === 'production' ? '/digital' : 'http://144.34.180.209/digital/'
const baseURL = process.env.NODE_ENV === 'production' ? '/digital' : axios.defaults.baseUR
// const baseURL = 'https://www.admaxai.com/digital'
//   ? 'http://144.34.180.209/'
//   : 'http://144.34.180.209/'
// const baseURL = ''
// 'http://game-rent-api.fat.springdance.cn'
// User-Agent-Device-Id
/* let timer
const headers = {
  'Content-Type': 'application/json'
}
if (device) {
  headers['User-Agent-Device-Id'] = device
} */

const instance = axios.create({
  baseURL,
  timeout: 300000,
  // headers
})
instance.interceptors.request.use(
  function (config) {
    if (config.params) {
      for (const i in config.params) {
        if (config.params[i] == null || (typeof config.params[i] === 'string' && config.params[i].trim() === '')) {
          delete config.params[i]
        }
      }
    }
    // if (config.data) {
    //   for (const i in config.data) {
    //     if (config.data[i] == null || (typeof config.data[i] === 'string' && config.data[i].trim() === '')) {
    //       delete config.data[i]
    //     }
    //   }
    // }
    // 'Authorization:  Bearer <your-api-key>'
    const token = localStorage.getItem('token')
    // 没有 token 就不需要Authorization(阳阳说的)
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete config.headers.Authorization
    }
    const language = localStorage.getItem('language')
    config.headers.lang = language === 'cn' ? 'zh_CN' : 'en_US'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {
    console.log(response.status)
    return response.data
  },
  function (error) {
    if (error.response.status === 401) {
      console.log(777777)
      localStorage.removeItem('token')
      Router.push('/login').then(r => console.log(r))
    }
    // Notification.error({
    //   title: 'error',
    //   message: `服务器(${error.response.status})错误~`
    // })
    return Promise.reject(error)
  }
)

export default instance
