<template>
  <div class="VideoItemWrapper">
    <div class="VideoCon" :style="{backgroundColor: videoData.background}" @click="openProject()">
      <video
        ref="myVideo"
        id="myAudio"
        class="video"
        disablePictureInPicture
        @click="openProject()"
        v-if="type === 'project' || type === 'view'"
      >
        <source :src="videoSrc" type="video/mp4" />
      </video>
      <!-- <img class="videoDown" :src="coverUrl" alt="" /> -->
      <el-image
        v-if="type === 'draft' && videoData.preview"
        fit="contain"
        :src="videoData.preview">
      </el-image>
    </div>
    <!-- <i class="el-icon-download videoDown" @click="handleDown"></i> -->
    <!-- <el-image
      :src="videoData.preview">
    </el-image> -->
    <div class="audio-right" :class="{'white': videoData.background !== '#FFFFFF'}" v-if="type === 'project'">
      <i
        :class="
          audioStatus !== 'pause'
            ? 'el-icon-caret-right'
            : 'el-icon-video-pause'
        "
        @click="playAudio"
        class="dialogAudioPlay"
      ></i>
      <div class="audio-time" style="min-height: 10px">
        <span class="audio-length-total">{{ `${duration}"` }}</span>
      </div>
    </div>
    <img
      v-if="type === 'project' && videoData.background === '#008000'"
      class="videoDown"
      src="~@/assets/img/downFFF.png"
      @click="handleDown"
      :title="$t('Down')"
      alt=""
    />
    <img
      v-if="type === 'project' && videoData.background !== '#008000'"
      class="videoDown"
      src="~@/assets/img/downBule.png"
      @click="handleDown"
      :title="$t('Down')"
      alt=""
    />
    <el-row>
      <el-col :span="20">
        <p class="title" :title="videoData.name">{{ videoData.name }}</p>
        <p class="createTime">{{ time }}</p>
      </el-col>
      <el-col :span="4" v-if="type !== 'view'">
        <!-- <el-button type="primary" v-if="type === 'project'" @click="handleShow()"> {{ $t('FaceTransplant') }} </el-button> -->
        <!-- <el-button type="primary" v-else @click="openProject()"> {{ $t('Edit') }} </el-button> -->
        <el-button type="primary" v-if="type !== 'project'" @click="openProject()"> {{ $t('Edit') }} </el-button>
        <i class="el-icon-delete" v-if="type === 'project'" @click="handleDelete"></i>
      </el-col>
    </el-row>
    <canvas id='mycanvas' v-show='false' ></canvas>
  </div>
</template>

<script>
import $ from 'jquery'
import { getFormateDate } from '@/utils/common'
export default {
  name: 'VideoItemWrapper',
  props: {
    type: {
      type: String,
      default: ''
    },
    videoData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      audioStatus: 'play',
      duration: '0',
      imageData: '',
      downBule: '~@/assets/img/downBule.png',
      downFFF: '~@/assets/img/downFFF.png',
      coverUrl: '',
      videoSrc: '',
      time: ''
    }
  },
  watch: {
    videoData: {
      handler: function (val) {
        console.log('videoData', val, val.background, this.videoData.url)
        // this.getPic(val)
        // const video = document.getElementById('myAudio')
        // const source = document.getElementsByTagName('source')
        // source.url = this.videoData.url
        // this.videoSrc = this.videoData.url
        this.$nextTick(() => {
          this.videoSrc = this.videoData.url
          // this.$refs.myVideo.load()
          if (this.$refs.myVideo) {
            this.$refs.myVideo.src = this.videoData.url
          }
          this.time = getFormateDate(this.videoData.dateCreated, 'yyyy/MM/dd hh:mm:ss')
          this.fetch()
          this.$forceUpdate()
        })
        // setTimeout(() => {
        //   // item.videoUrl  视频地址    item.coverUrl  图片地址
        //   // this.getVideoBase64(val.url).then((data) => {
        //   //   val.coverUrl = data
        //   // })
        //   this.shutThumb()
        //   // this.screenshot('myAudio', 'image/png').then(dataURL => {
        //   //   const form = new FormData()
        //   //   form.append('file', this.base64ToFile(dataURL))
        //   // })
        // }, 1000)
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.fetch()
    /* const img = this.$refs.img
    img.addEventListener('error', function () {
      this.src = require('@/assets/default-back.png')
    })
    const video = this.$refs.myVideo
    const canvas = this.$refs.myCanvas
    const ctx = canvas.getContext('2d')
    video.addEventListener('loadedmetadata', () => {
      /!* ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      this.imageData = canvas.toDataURL() *!/
      const fifthSecond = 5
      // 设置当前时间为第5帧的时间位置
      video.currentTime = fifthSecond * (1 / video.playbackRate)
      // 等待视频渲染第5帧
      video.addEventListener('seeked', () => {
      })
      setTimeout(() => {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        this.imageData = canvas.toDataURL()
      }, 10000)
      /!* const video = this.$refs.myVideo
    // 等待视频加载完成
    video.addEventListener('loadedmetadata', () => {
      // 计算第5帧的时间位置
      const fifthSecond = 5
      const fifthFrameTime = fifthSecond * (1 / video.playbackRate)

      // 设置当前时间为第5帧的时间位置
      video.currentTime = fifthFrameTime
      // 等待视频渲染第5帧
      video.addEventListener('seeked', () => {
        // 创建一个canvas元素
        const canvas = this.$refs.myCanvas
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        // 将当前帧渲染到canvas上
        const ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0)
        // 将canvas转换为图像数据URL
        this.imageData = canvas.toDataURL()
        console.log(canvas.toDataURL())
        // 输出图像数据URL
      })
    }) *!/
      /!*  const video = document.getElementById('myVideo')
    const canvas = document.getElementById('myCanvas')
    const context = canvas.getContext('2d')
    video.addEventListener('loadedmetadata', function () {
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      this.imageData = canvas.toDataURL()
    }) *!/
    }) */
  },
  methods: {
    fetch () {
      const that = this
      const myVid = this.$refs.myVideo
      if (!myVid) return
      // const myVid = document.getElementById('myAudio')
      myVid.loop = false
      // 监听音频播放完毕
      myVid.addEventListener(
        'ended',
        function () {
          that.audioStatus = 'play' // 显示播放icon
          // document.getElementById('progressBar').style.width = '0%' // 进度条初始化
        },
        false
      )
      if (myVid != null) {
        myVid.oncanplay = function () {
          that.duration = that.transTime(myVid.duration) // 计算音频时长
        }
        myVid.volume = 0.5 // 设置音量50%
      }
    },
    transTime (time) {
      return parseInt(time)
    },
    // 播放暂停控制
    playAudio () {},
    // playAudio () {
    //   const recordAudio = this.$refs.myVideo // 获取audio元素
    //   // const recordAudio = document.getElementById('myAudio')
    //   if (recordAudio.paused) {
    //     recordAudio.play()
    //     this.audioStatus = 'pause'
    //   } else {
    //     recordAudio.pause()
    //     this.audioStatus = 'play'
    //   }
    // },
    getPic () {
      const video = document.getElementsByClassName('video')[0]
      console.log(video, 66666)
      video.currentTime = 5 // 必须设置视频当前时长，要不然会黑屏
      // 创建画布准备截图
      const canvas = document.createElement('canvas')
      // 创建图片标签
      video.setAttribute('crossOrigin', 'anonymous')
      // 设置画布的宽高
      canvas.width = video.clientWidth
      canvas.height = video.clientHeight
      // 图片绘制
      canvas
        .getContext('2d')
        .drawImage(video, 0, 0, canvas.width, canvas.height)
      const dataURL = canvas.toDataURL('image/jpeg') // 转为base64图片
      console.log(dataURL)
      const file = this.base64ToFile(dataURL, 'my.jpg') // base64转为file
      console.log('file', file)
    },
    // // base64转file文件
    // base64ToFile (urlData, fileName) {
    //   const arr = urlData.split(',')
    //   const mime = arr[0].match(/:(.*?);/)[1]
    //   const bytes = atob(arr[1]) // 解码base64
    //   let n = bytes.length
    //   const ia = new Uint8Array(n)
    //   while (n--) {
    //     ia[n] = bytes.charCodeAt(n)
    //   }
    //   return new File([ia], fileName, { type: mime })
    // },

    async openProject () {
      if (this.type === 'project') {
        window.open(this.videoData.url, '_blank')
      } else if (this.type === 'draft') {
        const res = await this.$api.projectsDetail(this.videoData.projectId)
        this.$store.commit('setVideo', res.data)
        this.$router.push({
          name: 'workbench',
          params: {
            type: 'detail'
          },
        })
      }
    },
    shutThumb () {
      console.log('this.coverUrl', this.videoData.url)
      const size = 160
      // 获取video节点
      const video = document.getElementById('myAudio')
      const source = document.createElement('source')
      source.src = this.videoData.url
      source.type = 'video/mp4'
      video.appendChild(source)
      video.crossOrigin = 'anonymous'
      video.width = size
      video.height = size
      video.currentTime = 1 // 第一帧的时间
      video.oncanplay = () => {
        // 创建canvas对象
        // const canvas = document.createElement('canvas')
        const canvas = document.getElementById('mycanvas') // 获取 canvas 对象
        canvas.width = size
        canvas.height = size
        // 利用canvas对象方法绘图
        canvas
          .getContext('2d')
          .drawImage(video, 0, 0, canvas.width, canvas.height)
        // 转换成base64形式
        const img = canvas.toDataURL('image/png') // 这个就是图片的base64
        this.coverUrl = img
        console.log('this.coverUrl', this.coverUrl)
      }
    },
    screenshot (id, imgType) {
      const video = document.getElementById(id)
      const width = video.videoWidth
      const height = video.videoHeight
      // 创建画布准备截图
      // const canvas = document.createElement('canvas')
      const canvas = document.getElementById('mycanvas') // 获取 canvas 对象
      // 设置video属性
      video.setAttribute('crossOrigin', 'anonymous')
      // 设置画布的宽高
      canvas.width = width
      canvas.height = height
      // 图片绘制
      canvas.getContext('2d').drawImage(video, 0, 0, width, height)
      return new Promise((resolve, reject) => {
        try {
          const dataURL = canvas.toDataURL(imgType)
          resolve(dataURL)
        } catch (error) {
          reject(error)
        }
      })
    },
    base64ToFile (dataurl, filename = '') {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const suffix = mime.split('/')[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    // 转换第一帧
    cutPicture (item) {
      const video = document.createElement('video')
      video.crossOrigin = 'anonymous'
      video.style = 'position:fixed; top: 9999px;left:9999px;z-index:-9999'
      video.preload = 'metadata'
      video.currentTime = 1 // 截取的视频第一秒作为图片
      video.src = item.url
      video.setAttribute('crossOrigin', 'anonymous')
      video.width = 113
      video.height = 75

      // video.setAttribute('crossOrigin', 'anonymous') // 处理跨域
      // video.setAttribute('src', item.url)
      // video.setAttribute('width', 400)
      // video.setAttribute('height', 240)
      // video.setAttribute('preload', 'auto')
      // video.setAttribute('crossOrigin', 'anonymous')
      // document.body.appendChild(video)
      // video.addEventListener('loadeddat', function () {
      //   const canvas = document.createElement('canvas')
      //   const width = video.width // canvas的尺寸和图片一样
      //   const height = video.height
      //   canvas.width = width
      //   canvas.height = height
      //   canvas.getContext('2d').drawImage(video, 0, 0, width, height) // 绘制canvas
      //   const dataURL = canvas.toDataURL('image/jpeg') // 转换为base64
      //   this.coverUrl = dataURL
      // })

      video.onloadeddata = function () {
        const canvas = document.createElement('canvas')
        canvas.width = 113
        canvas.height = 75
        canvas
          .getContext('2d')
          .drawImage(video, 0, 0, video.clientWidth, video.clientHeight)
        const oGrayImg = canvas.toDataURL('image/jpeg')
        item.videoUrl = oGrayImg
        this.remove()
      }
      this.coverUrl = item.videoUrl
      console.log('this.coverUrl', this.coverUrl)
      return item
    },
    // 封装函数
    getVideoBase64 (url) {
      return new Promise(function (resolve, reject) {
        let dataURL = ''
        const video = document.createElement('video')
        video.setAttribute('crossOrigin', 'anonymous') // 处理跨域
        video.setAttribute('src', url)
        video.setAttribute('width', 400)
        video.setAttribute('height', 240)
        video.setAttribute('preload', 'auto')
        video.setAttribute('crossOrigin', 'anonymous')
        video.addEventListener('loadeddata', function () {
          const canvas = document.createElement('canvas')
          const width = video.width // canvas的尺寸和图片一样
          const height = video.height
          canvas.width = width
          canvas.height = height
          canvas.getContext('2d').drawImage(video, 0, 0, width, height) // 绘制canvas
          dataURL = canvas.toDataURL('image/jpeg') // 转换为base64
          resolve(dataURL)
        })
      })
    },
    handleDelete () {
      this.$emit('deleteVideo', this.videoData)
    },
    handleShow () {
      this.$emit('showDialog', this.videoData)
    },
    handleDown () {
      console.log('########', $)
      const link = document.createElement('a')
      link.download = 'video.mp4'
      link.href = this.videoData.url.replace('cos.ap-singapore', 'cossgp')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      // this.load()
      // this.downloadVideo(this.videoData.url.replace('cos.ap-singapore', 'cossgp'))
      // this.proxyv(this.videoData.url, function (data) {
      //   console.log(data)
      // })
      // this.loadScript(this.videoData.url, this.downloadVideo(this.videoData.url))
      // const link = document.createElement('a')
      // link.style.display = 'none'
      // link.href = this.videoData.url
      // link.setAttribute('download', '视频')
      // link.click()
      // document.body.appendChild(link)
    },
    // 视频下载，可以调用浏览器下载的进度条
    load () {
      // // 创建script的标签
      // const script = document.createElement('script')
      // // 拼接 url
      // const url = this.videoData.url + '?callback=' + '视频'
      // // 赋值url
      // script.src = url
      // // 放入头部
      // document.getElementsByTagName('head')[0].appendChild(script)
      // console.log('####%%%###', $)
      // $.ajax({
      //   type: 'GET',
      //   url: 'https://admax-1318679842.cos.ap-singapore.myqcloud.com/digital/20230903/f958057391c04398a5380ab2af829517.mp4',
      //   dataType: 'jsonp',
      //   // jsonp: 'callback',
      //   // jsonpCallback: 'f',
      //   success: function (res) {
      //     console.log('load', res)
      //   }
      // })
      // const video = this.$refs.video // video标签
      // const url = video.src
      // return fetch(this.videoData.url, {
      //   method: 'GET',
      //   cache: 'no-cache',
      //   responseType: 'blob',
      //   dataType: 'jsonp',
      //   jsonp: 'callback',
      //   jsonpCallback: 'f',
      // }).then(res => {
      //   const fileStream = window.streamSaver.createWriteStream('test.mp4', {
      //     size: res.headers.get('content-length')
      //   })
      //   const readableStream = res.body
      //   if (window.WritableStream && readableStream.pipeTo) {
      //     return readableStream.pipeTo(fileStream).then(() => console.log('done writing'))
      //   }
      //   window.writer = fileStream.getWriter()
      //   const reader = res.body.getReader()
      //   const pump = () => reader.read().then(res => res.done ? window.writer.close() : window.writer.write(res.value).then(pump))
      //   pump()
      // })
    },
    downloadVideo (url) {
      const videoUrl = url // 替换为你要下载的视频的URL
      const fileName = 'video.mp4' // 下载的文件名

      // 创建一个隐藏的a标签
      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)

      // 使用XMLHttpRequest下载视频
      const xhr = new XMLHttpRequest()
      xhr.open('GET', videoUrl, true)
      // xhr.responseType = 'blob'

      xhr.onload = function () {
        if (xhr.status === 200) {
          // 将视频Blob对象创建一个临时URL
          const videoBlob = xhr.response
          const url = window.URL.createObjectURL(videoBlob)

          // 设置a标签的属性，并触发点击事件进行下载
          a.href = url
          a.download = fileName
          a.click()

          // 释放URL对象
          window.URL.revokeObjectURL(url)
        }
      }

      xhr.send()
    },
    loadScript (url, func) {
      const head = document.head || document.getElementByTagName('head')[0]
      const script = document.createElement('script')
      script.src = url

      script.onload = script.onreadystatechange = function () {
        if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
          func()
          script.onload = script.onreadystatechange = null
        }
      }

      head.appendChild(script, 0)
    },
    // proxyv (url, func) {
    //   let isFirst = true
    //   let ifr = document.createElement('iframe')
    //   const loadFunc = function () {
    //     console.log('loadFunc')
    //     if (isFirst) {
    //       ifr.contentWindow.location = 'loc'
    //       isFirst = false
    //     } else {
    //       func(ifr.contentWindow.name)
    //       ifr.contentWindow.close()
    //       document.body.removeChild(ifr)
    //       ifr.src = ''
    //       ifr = null
    //     }
    //   }

    //   ifr.src = url
    //   ifr.style.display = 'none'
    //   if (ifr.attachEvent) ifr.attachEvent('onload', loadFunc)
    //   else ifr.onload = loadFunc

    //   document.body.appendChild(ifr)
    // }
  }
}
</script>

<style scoped lang="less">
.VideoItemWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  .VideoCon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    border-radius: 10px;
    border: 1px solid #DCE2EB;
    box-sizing: border-box;
    overflow: hidden;
    .el-image {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
  .videoDown {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    font-size: 18px;
  }
  img {
    width: 100%;
    height: 140px;
    border-radius: 4px;
    //background: url('~@/assets/default-back.png') no-repeat center;
  }
  video {
    // width: 100%;
    height: 100%;
  }
  .des {
    font-size: 13px;
    color: #999;
    display: flex;
    justify-content: space-between;
    .ex {
      color: #409eff;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .el-row {
    display: flex;
    align-items: center;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    .title {
      padding-left: 2px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
      margin-top: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .createTime {
      padding-left: 2px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      opacity: .5;
    }
    .el-button {
      height: 28px;
      background: #729cd5;
      border: #729cd5;
      border-radius: 5px;
      float: right;
    }
    i {
      float: right;
      margin-top: 36px;
    }
  }
  .audio-right {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
    font-weight: 800;
    color: #094391;
    width: 62px;
    height: 30px;
    background: #DCEBFF;
    border-radius: 15px;
    position: absolute;
    top: 15px;
    left: 15px;
    i {
      margin-right: 4px;
      margin-top: 0px;
      font-size: 20px;
    }
    .audio-time {
      min-width: 26px;
    }
    &.white {
      background: rgba(9, 67, 145, .2);
      color: #FFFFFF;
    }
  }
}
</style>
