<template>
  <div class="RoleWrapper">
    <div class="roleTop">
      <div class="menu">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('ChooseDigitalPerson')" name="1">
            <div class="tabList photoWhiteBg">
              <div class="language">
                <el-tag @click="selsctRegion('all')" :class="{'active': selectActive === 'all'}">
                  {{ $t('All') }}
                </el-tag>
                <el-tag v-for="(k, i) in regionsList" :key="i" v-show="i < 3" @click="selsctRegion(k)" :class="{'active': selectActive === k}">
                  {{ k }}
                </el-tag>
                <el-popover placement="bottom-end" width="352" trigger="hover" v-show="regionsList.length >= 2">
                  <el-tag v-for="(k, i) in regionsList" :key="i" @click="selsctRegion(k)" :class="{'active': selectActive === k}" style="margin-top: 10px;">
                    {{ k }}
                  </el-tag>
                  <el-button slot="reference">{{ $t('More') }} <i class='el-icon-arrow-right' /> </el-button>
                </el-popover>
              </div>
              <div class="con">
                <div class="upwra">
                  <div class="con custom-scroll SelectAvatarsPhotos" style="height: auto">
                    <div class="item" v-for="(item, index) in avatarsList" :key="'a' + index" @click="handleSelectPhotos(item, index, 'avatar')">
                      <div class="imgwrapper" :class="{ active: selectPhoto === index }">
                        <img :src="item.url" alt="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <p></p> -->
            </div>
            <el-divider />
            <div class="roleBottom">
              <div class="menu">
                <span>{{ $t('ChooseAccessories') }}</span>
              </div>
              <div class="con">
                <div class="upwra">
                  <div class="con custom-scroll">
                    <div class="item" v-for="(k, i) in avatarsChildren" :key="'c' + i" @click="handleSelectPhotos(k, i, 'avatar')">
                      <div class="imgwrapper" :class="{ active: selectChildPhoto === i }">
                        <img :src="k.url" alt="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('UploadPhotos')" name="2">
            <div class="tabList photoWhiteBg" style="width: 100%;">
              <div class="upwra uploadPhotosWarp">
                <div class="con custom-scroll">
                  <div class="item" style='align-items: center;
                      justify-content: center'>
                    <el-upload
                      class="upload-demo avatar-uploader"
                      accept=".png, .jpg"
                      action
                      :http-request="handleUploadForm"
                      :headers="headers"
                      :before-upload="beforeAvatarUpload"
                      :loading='loading'
                      :show-file-list="false"
                      >
                      <i class="el-icon-plus avatar-uploader-icon" style="font-size: 40px;"></i>
                      <!-- <el-button size="small" type="primary">上传照片</el-button> -->
                    </el-upload>
                  </div>
                  <div class="item" v-for="(k, i) in talkingPhoto" :key="i" @click="handleSelectPhotos(k, i, 'talking_photo')">
                    <div class="imgwrapper"  :class="{active: selectTalkPhoto === i}">
                      <img :src="k.url" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <!-- <span>选择数字人</span>
            <el-upload
              class="upload-demo"
              :action="action"
              :show-file-list="false"
              :on-success="handleSuccess">
              <el-button size="small" type="primary">上传照片</el-button>
            </el-upload> -->
      </div>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery'

export default {
  name: 'RoleWrapper',
  props: {
    language: {
      type: Array,
      default: () => {
        return []
      }
    },
    avatars: {
      type: Array,
      default: () => {
        return []
      }
    },
    talkingPhoto: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  watch: {
    avatars (val) {
      if (val.length > 0) {
        this.avatarsChildren = this.avatars[0].children
        this.getRegions()
        this.selectPhoto = '0'
        this.selectChildPhoto = 0
      }
    },
  },
  data () {
    return {
      activeName: '1',
      active: 1,
      selectPhoto: null,
      loading: false,
      selectActive: 'all',
      photos: [],
      imgUrl: '',
      file: null,
      avatarsChildren: [],
      selectChildPhoto: '',
      selectTalkPhoto: 0,
      regionsList: [],
      avatarsList: [],
    }
  },
  computed: {
    // host () {
    //   return process.env.NODE_ENV === 'production' ? '/digital' : 'http://144.34.180.209/digital/'
    // },
    // action () {
    //   return `${this.host}api/v1/materials/uploadTalkingPhoto`
    // },
    headers () {
      const token = localStorage.getItem('token')
      return { Authorization: `Bearer ${token}` }
    }
  },
  mounted () {
  },
  methods: {
    getRegions () {
      // const res = await this.$api.getRegions({
      //   page: 1,
      //   size: 9999
      // })
      const language = localStorage.getItem('language') || 'cn'

      if (language === 'cn') {
        this.regionsList = [...new Set(this.avatars.filter(e => e.region).map(e => e.region))]
      } else if (language === 'en') {
        this.regionsList = [...new Set(this.avatars.filter(e => e.regionEn || e.region).map(e => e.regionEn || e.region))]
      }
      console.log('regionsList', this.regionsList)
      this.selsctRegion('all')
    },
    // 查询地区
    selsctRegion (type) {
      console.log('type', type)
      if (type === 'all') {
        this.avatarsList = Object.assign({}, this.avatars)
      } else {
        // 国际化 根据中英文 取后端不同的字段（产品 要求这样做的）
        const language = localStorage.getItem('language') || 'cn'

        if (language === 'cn') {
          this.avatarsList = this.avatars.filter(e => e?.region?.indexOf(type) > -1)
        } else if (language === 'en') {
          this.avatarsList = this.avatars.filter(e => e?.regionEn?.indexOf(type) > -1)
        }
      }
      this.avatarsChildren = this.avatarsList[0].children
      this.selectPhoto = ''
      this.selectChildPhoto = ''
      this.selectActive = type
      console.log('this.avatarsList', this.avatarsList)
      console.log(3333, this.selectPhoto)
      this.$forceUpdate()
    },
    handleSelectPhotos (k, i, type) {
      // this.selectPhoto = k.id
      if (k.type === '0') {
        this.selectPhoto = i
        this.avatarsChildren = k.children
        this.selectChildPhoto = 0
      } else if (k.type === '1') {
        this.selectTalkPhoto = i
      } else {
        this.selectChildPhoto = i
      }
      // this.avatars
      // this.$emit('confirm', {
      //   avatar_id: null,
      //   talking_photo_id: k.id,
      //   url: k.image_url
      // })
      k = Object.assign({}, k, {
        characterType: type
      })
      this.$emit('confirm', k)
    },
    handleFile (file) {
      this.file = file.target.files[0]
      this.imgUrl = URL.createObjectURL(file.target.files[0])
    },
    handleUp () {
      if (this.imgUrl) return
      this.$refs.file.click()
    },
    handleClear () {
      if (this.loading) return
      this.imgUrl = ''
      this.file = null
      this.$refs.file.value = ''
    },
    beforeAvatarUpload (file) {
      console.log('file', file)
    },
    // 上传文件
    async handleUploadForm (param) {
      console.log('handleUploadForm', param)
      const formData = new FormData()
      // 额外参数
      // formData.append('name', param.file.name)
      formData.append('file', param.file)
      // const loading = thiz.$loading({
      //   lock: true,
      //   text: '上传中，请稍候...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      this.$message(this.$t('HintText.UploadingPleaseWait'))
      this.loading = true
      try {
        await this.$api.uploadTalkingPhoto(formData, param.file.name)
        this.$message({
          message: this.$t('HintText.uploadSuccessful'),
          type: 'success'
        })
        this.loading = false
        this.$emit('confirmUpload', '')
        // await this.handlePhotos()
        // this.$forceUpdate()
      } catch (error) {
        this.loading = false
        this.$message({
          message: error.response.data.msg || this.$t('HintText.ThisImageDoesNotMeetStandardsCannotRecognized'),
          type: 'error'
        })
      }
    },
    async handleUpload () {
      if (!this.file) return
      const formData = new FormData()
      formData.append('file', this.file)
      this.loading = true
      try {
        await this.$api.uploadTalkingPhoto(formData)
        this.$message({
          message: this.$t('HintText.uploadSuccessful'),
          type: 'success'
        })
        this.loading = false
        this.handleClear()
        await this.handlePhotos()
        this.$forceUpdate()
      } catch (error) {
        this.loading = false
        this.$message({
          message: error.response.data.msg || this.$t('HintText.ThisImageDoesNotMeetStandardsCannotRecognized'),
          type: 'error'
        })
      }
    },
    handleSuccess (response, file, fileList) {
      console.log(response, file, fileList)
    },
  }
}
</script>

<style scoped lang="less">
.RoleWrapper {
  .el-divider--horizontal {
    margin: 26px 0 6px;
  }

  .roleTop {
    padding: 15px 0 0px;
  }
  .tabList {
    padding: 0 20px 11px;
  }
  .photoWhiteBg {
    position: relative;
    &::after {
      display: inline-block;
      content: '';
      width: 100%;
      height: 70px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 50%, #ffffff 100%);
      position: absolute;
      bottom: 10px;
      left: 0;
    }
  }
  .roleBottom {
    padding: 0 20px;
    .menu {
      font-size: 18px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      padding: 12px 0 4px
    }
  }

  .custom-scroll {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #C1C1C1;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
      background: #F1F1F1;
    }
  }

  .menu {
    display: flex;
    // margin-bottom: 15px;
    color: #094391;
    align-items: center;
    /deep/.el-tabs {
      .el-tabs__header {
        padding: 0 20px;
        margin: 0 0 14px;
      }
      .el-tabs__nav-wrap {
        &::after {
          display: none;
        }
        .el-tabs__active-bar {
          display: none;
        }
      }
    }

    span {
      // padding: 0 20px;
      cursor: pointer;
    }

    .upload-demo {
      .el-button {
        background: none;
        border: none;
        color: #094391;
        opacity: .4;
      }
    }
  }

  .language {
    position: relative;
    padding-right: 80px;
    height: 36px;
    overflow: hidden;
    .el-tag {
      font-size: 16px;
      // width: 72px;
      text-align: center;
      height: 34px;
      line-height: 34px;
      cursor: pointer;
      margin-right: 18px;
      padding: 0px  10px;
      background: #F3F8FF;
      overflow: hidden;
      color: #8BA0BD;
      letter-spacing: 1px;

      // &:nth-of-type(1) {
      //   background: #1891AC;
      //   color: #fff;
      // }
      &.active {
        background: #1891AC;
        color: #fff;
        font-weight: bold;
      }
    }

    .el-popover__reference-wrapper {
      vertical-align: middle;
      padding: 20px;

      .el-button {
        width: 75px;
        // float: right;
        position: absolute;
        right: 10px;
        top: 0;
        padding: 0 0 0 10px;
        height: 34px;
        background: #F3F8FF;
        color: #094391;
        letter-spacing: 1px;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 600;
        i {
          font-size: 10px;
        }
      }
    }
  }

  .con {
    display: flex;
    flex-wrap: wrap;
    height: 428px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 340px;
    margin-top: 10px;

    .item {
      width: 100px;
      margin-right: 12px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      height: 100px;
      position: relative;
      overflow: hidden;
      .el-card {
        width: 100%;
        height: 100%;
      }

      .imgwrapper {
        height: 100px;
        width: 100px;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 10px;
        overflow: hidden;
        // border: 2px solid transparent;
        box-sizing: border-box;
        border: 1px solid #EBEEF5;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        outline: none;
        box-sizing: border-box;
      }

      .active {
        border: 2px solid #003399;
      }

      span {
        line-height: 30px;
        position: absolute;
        width: 100%;
        text-align: center;
        left: 0;
        bottom: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .upwra {
    display: flex;
    align-items: center;
    flex-direction: column;
    // margin-top: 15px;
    height: 100%;

    .up {
      width: 100px;
      height: 100px;
      position: relative;
      border: 1px dashed #CCC;
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      .iconup {
        font-size: 40px;
        color: #999;
      }

      .clickup {
        font-size: 13px;
      }

      img {
        width: 100%;
        border: none;
        outline: none;
        border-radius: 5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      div {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;

        i {
          color: #FFF;
          font-size: 30px;
          cursor: pointer;
        }
      }

      &:hover {
        div {
          display: flex;
          z-index: 1111;
        }
      }
    }
  }
  .uploadPhotosWarp {
    min-height: 200px;
  }

  .roleBottom {
    .con {
      max-height: 230px;
    }
  }
}

.el-tag {
  font-size: 16px;
  // width: 72px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  margin-right: 18px;
  // padding: 0px;
  background: #F3F8FF;
  overflow: hidden;
  color: #8BA0BD;
  letter-spacing: 1px;
  &.active {
    background: #1891AC;
    color: #fff;
  }
}
/deep/.el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  color: #094391;
  opacity: .4;
}
/deep/.el-tabs__item.is-active {
  font-size: 18px;
  font-weight: bold;
  opacity: 1;
}
.el-tabs__content {
  .el-tab-pane:nth-of-type(2) {
    .con {
      height: auto;
      max-height: 770px;
    }
  }
}
</style>
